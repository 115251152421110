import { useEffect, useState } from "react";
import {
  Select,
  InputNumber,
  Button,
  Form,
  Flex,
  notification,
  Typography,
  Avatar,
} from "antd";
import localStorageCache from "../cache";
import { getRank, guessItem } from "../actions/actions";
import RankingTable, { DataItem } from "./RankingTable";

const { Title } = Typography;

const { Option } = Select;

const MyForm = () => {
  const userName = localStorageCache.get("name");
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [dataArray, setDataArray] = useState<DataItem[]>([]);
  const handleOptionChange = () => {
    form.resetFields(["price"]);
  };

  const fetchRank = async () => {
    setIsLoading(true);
    const res = await getRank();
    setDataArray(res.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRank();
  }, []);

  const onFinish = async (values: { item: number; price: number }) => {
    try {
      await guessItem({ ...values, name: userName });
      await fetchRank();
      notification.success({
        message: `成功竞猜${values.item}号商品，价格为$${values.price}`,
        duration: 5,
        placement: "top",
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Flex style={{ marginBottom: 10 }} justify="space-between" align="center">
        <Avatar
          style={{ marginLeft: "5%" }}
          src="https://api.dicebear.com/7.x/miniavs/svg?seed=1"
        />
        <Title
          style={{ marginRight: "5%", marginTop: 0, marginBottom: 0 }}
          level={5}
        >
          当前用户：{userName}
        </Title>
      </Flex>

      <Form
        form={form}
        onFinish={onFinish}
        style={{ marginTop: 20, width: "90%", display: "block" }}
      >
        <Flex justify="space-between" align="flex-end">
          <Form.Item
            name="item"
            label="选择物品"
            rules={[{ required: true, message: "请选择一个物品" }]}
            style={{ marginLeft: "5%", marginRight: 10, width: "43%" }}
          >
            <Select
              placeholder="请选择1-12的物品"
              onChange={handleOptionChange}
              style={{ width: "100%" }}
            >
              {Array.from({ length: 12 }, (_, i) => (
                <Option key={i + 1} value={i + 1}>
                  {i + 1}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="price"
            label="价格（美元）"
            rules={[{ required: true, message: "请输入价格" }]}
            style={{ width: "30%" }}
          >
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              step={0.01}
              precision={2}
            />
          </Form.Item>

          <Form.Item style={{ width: "10%" }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Flex>
      </Form>

      <RankingTable dataArray={dataArray} loading={isLoading} />
    </>
  );
};

export default MyForm;
