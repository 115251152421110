import React, { useState, useEffect } from "react";
import { Table, Flex } from "antd";
import type { ColumnsType } from "antd/es/table";

export interface DataItem {
  name: string;
  value: number;
  counts: number;
}

interface RankingTableProps {
  loading: boolean;
  dataArray: DataItem[];
}

const RankingTable: React.FC<RankingTableProps> = ({ dataArray, loading }) => {
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    // 对数据进行排序
    if (dataArray.length > 0) {
      const sortedData = [...dataArray].sort((a, b) => {
        if (b.counts !== a.counts) {
          return b.counts - a.counts;
        }
        return a.value - b.value;
      });

      setData(sortedData);
    } else {
      setData([]); // 如果 dataArray 为空，则设置 data 为空数组
    }
  }, [dataArray]);

  const columns: ColumnsType<DataItem> = [
    {
      title: "排名",
      key: "rank",
      render: (_: any, __: DataItem, index: number) => index + 1,
    },
    {
      title: "玩家",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "分数",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "竞猜个数",
      dataIndex: "counts",
      key: "counts",
    },
  ];

  return (
    <Flex justify="center" align="center" vertical>
      <Table
        style={{ width: "90%" }}
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="name"
        size="small"
        pagination={false}
        bordered
      />
    </Flex>
  );
};

export default RankingTable;
