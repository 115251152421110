import {
  useEffect,
  // useEffect,
  useState,
} from "react";
import "./assets/css/App.css";
import { ConfigProvider } from "antd";
import { Button, Col, Row, Input, Flex, Typography } from "antd";
import { initPlayer } from "./actions/actions";
import localStorageCache from "./cache";
import LoadingPage from "./pages/Loading";
import MyForm from "./components/Form";
import Keer from "./assets/images/keer.jpg";
import Zzz from "./assets/images/zzz.jpg";

const { Title } = Typography;

const App = () => {
  const [name, setName] = useState("");
  const [isNewUser, setIsNewUser] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const createPlayer = async () => {
    try {
      await initPlayer(name);
      localStorageCache.set("name", name);
      setIsNewUser(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const sessionName = localStorageCache.get("name");
    if (sessionName) {
      setIsNewUser(false);
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <ConfigProvider>
      <Row>
        <Col span={24}>
          <Flex justify="space-between">
            <img src={Zzz} alt="zzz" style={{ width: 100, marginLeft: 20 }} />
            <img src={Keer} alt="zzz" style={{ width: 100, marginRight: 20 }} />
          </Flex>
          <Flex justify="center" align="center">
            <Title level={3}>慧眼识真价，排名见高低</Title>
          </Flex>
        </Col>
        <Col span={24}>
          {isNewUser ? (
            <Flex justify="center" align="center" vertical>
              <Input
                key="name-input"
                placeholder="请输入玩家姓名"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  width: "50%",
                }}
              />
              <Button
                key="create-player"
                style={{ marginTop: 0 }}
                onClick={() => createPlayer()}
              >
                创建
              </Button>
            </Flex>
          ) : (
            <MyForm />
          )}
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default App;
