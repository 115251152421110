import axios from "../utils/axios";

export const initPlayer = (name: string) =>
  axios.post("/init-player", {
    name,
  });

export const getRank = () => axios.get(`/get-rank`);

export const guessItem = (value: {
  name: string;
  price: number;
  item: number;
}) => axios.post(`guess`, value);
