import axios from "axios";
import { getBaseUrl } from "./apiConstants";
import { notification } from "antd";

let headers = {};

const createAxiosClient = () => {
  return axios.create({
    baseURL: getBaseUrl(),
    headers: {
      ...headers,
    },
  });
};

const axiosClient = createAxiosClient();

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const message = error.response?.data || error.message;

    notification.error({
      message: message,
      duration: 5,
      placement: "top",
    });

    return Promise.reject(error);
  }
);

export default axiosClient;
